import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/juridische-kennisgeving/"> Juridische Kennisgeving</a>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-38">
            <h1>Juridische Kennisgeving</h1>
            <p>
              Alle informatie op deze site is eigendom van Johnson &amp; Johnson
              Consumer BV. en valt ook onder de controle van Johnson &amp;
              Johnson BV. Deze informatie wordt beschermd door de internationale
              rechten op het copyright. U mag de informatie op deze site enkel
              en alleen downloaden voor persoonlijk gebruik zonder commerciële
              doeleinden. Informatie aanpassen of in de toekomst zelf publiceren
              is daarbij niet toegestaan. De inhoud van de site mag in geen
              enkel ander geval worden gekopieerd of gebruikt.
            </p>
            <p>
              Johnson &amp; Johnson Consumer BV. zal de nodige inspanningen
              leveren om up-to-date en juiste informatie op deze internetsite te
              plaatsen, maar onthoudt zich van uitspraken, garanties of beloftes
              over de juistheid, gangbaarheid of volledigheid van de geleverde
              informatie. Johnson &amp; Johnson Consumer BV. is niet
              aansprakelijk voor schade of letsel als gevolg van het al dan niet
              toegang krijgen tot de internetsite, of als gevolg van het
              vertrouwen dat u in de informatie op de internetsite heeft
              gesteld. Deze website voorziet eventueel links of verwijzingen
              naar andere sites, maar Johnson &amp; Johnson Consumer BV. is niet
              verantwoordelijk voor de inhoud van zulke andere sites. Johnson
              &amp; Johnson Consumer BV. kan ook niet aansprakelijk gesteld
              worden voor schade of letsel als gevolg van de informatie op die
              sites. Dergelijke links zijn enkel voorzien voor het gemak van de
              gebruikers van deze internetsite. Deze site is uitsluitend bestemd
              voor bezoekers die in Nederland wonen.
            </p>
            <p>
              De handelsmerken, dienstmerken, handelsnamen, productaankleding en
              producten die op deze site worden vermeld en getoond, zijn
              beschermd, zowel in de Benelux als internationaal. Geen enkele
              ervan mag worden gebruikt zonder voorafgaande schriftelijke
              toestemming van Johnson &amp; Johnson Consumer BV., behalve om de
              producten of diensten van van Johnson &amp; Johnson Consumer BV.
              te onderscheiden.
            </p>
            <p>
              Alle communicatie die u naar deze internetsite stuurt, wordt
              beheerd volgens de Internet{" "}
              <a href="/privacybeleid/">Privacyverbintenis</a> van deze
              internetsite. van Johnson &amp; Johnson Consumer BV. is vrij om de
              inhoud van die communicatieboodschappen - ook als het om ideeën,
              uitvindingen, concepten, technieken of knowhow gaat - te gebruiken
              voor eender welk doel dat bij het begin van de
              communicatie-uitwisselingen wordt vermeld. Zulke doelen kunnen de
              ontwikkeling, de productie en de marketing van nieuwe producten of
              diensten zijn.
            </p>
            <p>
              De zender van eender welke boodschap naar deze internetsite of
              naar van Johnson &amp; Johnson Consumer BV. is verantwoordelijk
              voor de inhoud en de informatie van zijn of haar boodschap, ook
              voor de waarheid en de juistheid van de gegevens.
            </p>
            <p>
              Deze internetsite wordt verleend als een dienst aan zijn
              bezoekers. van Johnson &amp; Johnson Consumer BV. houdt zich het
              recht voor om de inhoud van deze site op eender welk moment of
              voor eender welke reden te verwijderen, aan te passen of aan te
              vullen, zonder enige voorafgaande berichtgeving.    
              <br />
              Johnson &amp; Johnson Consumer BV, Postbus 1346, 1300 BH Almere
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
